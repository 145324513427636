import React, { useEffect, useState } from "react";

import { SelectComponent } from "../../../../general/features/heat_maps/SelectComponent";

const Features = ({ setterCallback }) => {
  const selectArray = ["General"];
  const [selectedOptions, setSelectedOptions] = useState({
    General: "",
  });

  const [optionData, setOptionData] = useState({
    General: {
      data: [
        { value: "General", label: "GENERAL" },
        { value: "Line Intrusion", label: "LINE_INTRUSION" },
        { value: "Zone Intrusion", label: "ZONE_INTRUSION" },
        { value: "Fire and Smoke", label: "FIRE_AND_SMOKE" },
        {
          value: "PPE Detection",
          label: "PPE_DETECTION",
        },
      ],
      currentPage: 1,
      hasMore: false,
      isLoading: false,
    },
  });

  const handleOptionChange = (optionType, value) => {
    if (optionType === "General") {
      setSelectedOptions((prevData) => ({
        ...prevData,
        General: value,
      }));
    }
  };

  const handleScroll = () => {};

  useEffect(() => {
    setterCallback(selectedOptions);
  }, [selectedOptions]);

  return (
    <div className="">
      {selectArray?.map((optionType, index) => (
        <SelectComponent
          key={index}
          optionType={optionType}
          selectedOptions={selectedOptions}
          optionData={optionData}
          handleOptionChange={handleOptionChange}
          handleScroll={handleScroll}
        />
      ))}
    </div>
  );
};

export default Features;

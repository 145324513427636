import React from "react";

const ChartOneSvg = ({ fillColor = "black" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.9 10.6667H10.1C10.3 10.6667 10.5 10.4 10.5 10.1333V4.53333C10.5 4.26667 10.3 4 10.1 4H8.9C8.7 4 8.5 4.26667 8.5 4.53333V10.1333C8.5 10.4 8.7 10.6667 8.9 10.6667ZM11.9 10.6667H13.1C13.3 10.6667 13.5 10.4 13.5 10.1333V0.533334C13.5 0.266667 13.3 0 13.1 0H11.9C11.7 0 11.5 0.266667 11.5 0.533334V10.1333C11.5 10.4 11.7 10.6667 11.9 10.6667ZM2.9 10.6667H4.1C4.3 10.6667 4.5 10.4 4.5 10.1333V7.2C4.5 6.93333 4.3 6.66667 4.1 6.66667H2.9C2.7 6.66667 2.5 6.93333 2.5 7.2V10.1333C2.5 10.4 2.7 10.6667 2.9 10.6667ZM5.9 10.6667H7.1C7.3 10.6667 7.5 10.4 7.5 10.1333V1.86667C7.5 1.6 7.3 1.33333 7.1 1.33333H5.9C5.7 1.33333 5.5 1.6 5.5 1.86667V10.1333C5.5 10.4 5.7 10.6667 5.9 10.6667Z"
        fill={fillColor}
      />
      <rect y="12.9996" width="16" height="3" rx="1" fill={fillColor} />
    </svg>
  );
};

export default ChartOneSvg;

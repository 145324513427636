import React, { useRef, useState, useEffect } from "react";

import { FaBell, FaInfoCircle } from "react-icons/fa";
import styled from "styled-components";

// Dummy notifications data
const notifications = [
  {
    title: "Notification Name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dapibus semper quam eu tincidunt.",
    time: "12:45 PM",
  },
  {
    title: "Notification Name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dapibus semper quam eu tincidunt.",
    time: "12:45 PM",
  },
  {
    title: "Notification Name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dapibus semper quam eu tincidunt.",
    time: "12:45 PM",
  },
  {
    title: "Notification Name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dapibus semper quam eu tincidunt.",
    time: "12:45 PM",
  },
  {
    title: "Notification Name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dapibus semper quam eu tincidunt.",
    time: "12:45 PM",
  },
  {
    title: "Notification Name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dapibus semper quam eu tincidunt.",
    time: "12:45 PM",
  },
  {
    title: "Notification Name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dapibus semper quam eu tincidunt.",
    time: "12:45 PM",
  },
  {
    title: "Notification Name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dapibus semper quam eu tincidunt.",
    time: "12:45 PM",
  },
  {
    title: "Notification Name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dapibus semper quam eu tincidunt.",
    time: "12:45 PM",
  },
  {
    title: "Notification Name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dapibus semper quam eu tincidunt.",
    time: "12:45 PM",
  },
  {
    title: "Notification Name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dapibus semper quam eu tincidunt.",
    time: "12:45 PM",
  },
];

const RecentNotifications = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const listRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = listRef.current.scrollTop;
      const itemHeight = listRef.current.scrollHeight / notifications.length;
      const currentIndex = Math.floor(scrollPosition / itemHeight);
      setActiveIndex(currentIndex);
    };

    // listRef.current.addEventListener("scroll", handleScroll);
    // return () => listRef.current.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Container>
      {/* Header */}
      <Header>
        <TitleWrapper>
          <Title>Recent Notifications</Title>
          <Subtitle>See the most recent notifications here</Subtitle>
        </TitleWrapper>
        <BellIconWrapper>
          <FaBell />
          <Badge>1</Badge>
        </BellIconWrapper>
      </Header>

      {/* Notifications List */}
      <NotificationList ref={listRef}>
        {notifications.map((notification, index) => (
          <NotificationItem key={index} isActive={index === activeIndex}>
            <NotificationIcon />
            <NotificationContent>
              <NotificationTitle>{notification.title}</NotificationTitle>
              <NotificationDescription>
                {notification.description}
              </NotificationDescription>
            </NotificationContent>
            <NotificationTime>{notification.time}</NotificationTime>
            {index === activeIndex && <ActiveIndicator />}
          </NotificationItem>
        ))}
      </NotificationList>
    </Container>
  );
};

export default RecentNotifications;

const Container = styled.div`
  width: 100%;
  max-width: 450px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #b71c1c;
  border-radius: 8px 8px 0 0;
  padding: 16px;
  color: #fff;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 24px;
  font-family: Poppins;
  font-weight: 600;
  margin: 0;
`;

const Subtitle = styled.p`
  font-size: 0.875rem;
  margin: 4px 0 0;
`;

const BellIconWrapper = styled.div`
  position: relative;
  font-size: 30px;
  font-weight: 400;
  font-family: Poppins;
  color: #ffbc5a;
`;

const Badge = styled.span`
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: #f44336;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 2px 6px;
  border-radius: 50%;
`;

const NotificationList = styled.div`
  padding: 16px;
  max-height: 410px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  background-color: white;
  border-top: none;
  border-radius: 0 0 8px 8px;
  position: relative;
`;

const NotificationItem = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 12px 0;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  &:last-child {
    border-bottom: none;
  }
`;

const NotificationIcon = styled(FaInfoCircle)`
  font-size: 50px;
  color: #b71c1c;
  margin-right: 12px;
`;

const NotificationContent = styled.div`
  flex-grow: 1;
`;

const NotificationTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  font-family: Inter;
  color: #a70101;
  margin: 0;
`;

const NotificationDescription = styled.p`
  font-size: 0.875rem;
  color: #757575;
  margin: 4px 0 0;
`;

const NotificationTime = styled.span`
  font-size: 0.875rem;
  color: #9e9e9e;
  white-space: nowrap;
  margin-left: auto;
`;

// Active Indicator Style
const ActiveIndicator = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #b71c1c;
  border-radius: 4px;
`;

import React from "react";

function VpsIcon(mobile = true) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g stroke="#787878" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" clipPath="url(#clip0_868_47)">
        <path d="M11.97 22c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"></path>
        <path strokeMiterlimit="10" d="M12 16.23a4.23 4.23 0 100-8.46 4.23 4.23 0 000 8.46z"></path>
      </g>
      <defs>
        <clipPath id="clip0_868_47">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default VpsIcon;

import "../../styles/account_auth.css";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axiosInstance from "../../config/axios";
import displayError from "../../helpers/displayError";
import { notifyFailure } from "../../helpers/notifications/notifyFailure";
import { useSelector } from "react-redux";
import TriImg from "../../static/img/Polygon 6.png";
import Logo from "../../static/img/camzify.png";
import {
  Intro,
  LeftSection,
  LoginCard,
  LoginContainer,
  MobileLeftSection,
  RightSection,
} from "./styledComponent";
import Spinner from "../Spinner";

const Register = () => {
  const { theme } = useSelector((state) => state.themeReducer);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [isProcessing, setIsProcessing] = useState(false);

  const handleRegister = async () => {
    if (password1 !== password2) {
      notifyFailure("Your password fields does not match each other");
      return;
    }
    setIsProcessing(true);
    (await axiosInstance())
      .post("/auth/register", {
        username,
        email,
        password: password1,
      })
      .then((response) => {
        setIsProcessing(false);
        //
        if (window.confirm("Account Creation Successful.\nProceed to login?")) {
          navigate("/login", { replace: true });
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        displayError(err, theme);
      });
  };
  return (
    <LoginContainer>
      <LeftSection>
        <Intro>
          <h1>
            SECURE & INTELLIGENT <br />
            <span>
              <img
                src={TriImg}
                alt='triangle'
                style={{ marginRight: "10px" }}
                height={30}
              />
            </span>
            SURVEILLANCE
          </h1>
          <p>
            Camzify is a robust desktop application designed for secure and
            efficient surveillance management.
          </p>
        </Intro>
      </LeftSection>

      {/* Show MobileLeftSection on small screens */}
      <MobileLeftSection />

      <RightSection>
        <LoginCard>
          <div className='d-flex flex-column gap-5'>
            <img src={Logo} alt='logo' />
            <div className='auth-main-text'>
              <span className='reg-span'>Register to your Account </span>
              <span className='login-desc'>
                Access your account securely to manage your settings and view
                your data.
              </span>
            </div>
          </div>
          <form className=''>
            <input
              type='text'
              onChange={(e) => setUsername(e.target.value)}
              className={`form-control form-control-custom mt-3 ${
                theme === "dark" && "form-control-custom-dark"
              }`}
              id='username'
              aria-describedby='emailHelp'
              placeholder='Username'
            />
            <input
              type='email'
              onChange={(e) => setEmail(e.target.value)}
              className={`form-control form-control-custom mt-3 ${
                theme === "dark" && "form-control-custom-dark"
              }`}
              id='email'
              aria-describedby='emailHelp'
              placeholder='Email'
            />
            <input
              type='password'
              onChange={(e) => setPassword1(e.target.value)}
              className={`form-control form-control-custom mt-3 ${
                theme === "dark" && "form-control-custom-dark"
              }`}
              id='password1'
              placeholder='Password'
            />
            <input
              type='password'
              onChange={(e) => setPassword2(e.target.value)}
              className={`form-control form-control-custom mt-3 ${
                theme === "dark" && "form-control-custom-dark"
              }`}
              id='password2'
              placeholder='Confirm Password'
            />

            <div className='d-flex justify-content-center my-3'>
              {isProcessing ? (
                <div className='m-3'>
                  <Spinner size={1.2} rot_time={0.5} />{" "}
                </div>
              ) : (
                <button
                  disabled={isProcessing}
                  type='submit'
                  onClick={(e) => {
                    e.preventDefault();
                    handleRegister();
                  }}
                  className={`fw-bold  px-5 ${
                    theme === "dark" && "btn-custom-orange-dark"
                  }`}
                >
                  Register
                </button>
              )}
            </div>
            <div className='d-flex align-items-center justify-content-center text-center w-100 flex-column mt-3'>
              <div
                style={{
                  color: theme === "dark" && "white",
                }}
              >
                Already have account?
              </div>
              <Link
                className={`orange-text text-small mx-1 ${
                  theme === "dark" && "orange-text-dark"
                }`}
                to='/login'
              >
                Login to your account
              </Link>
            </div>
          </form>
        </LoginCard>
      </RightSection>
    </LoginContainer>
  );
};

export default Register;

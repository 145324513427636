import styled from "styled-components";

export const LoginContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
`;
export const LeftSection = styled.div`
  flex: 0 0 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #a70101;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: -150px;
    right: -110px;
    width: 350px;
    height: 350px;
    background: #f97878;
    border-radius: 50%;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    filter: blur(80px);
    z-index: 1;
  }

  /* Hide LeftSection on mobile screens */
  @media (min-width: 769px) and (max-width: 900px) {
    flex: 0 0 50%;
  }

  /* Hide LeftSection on mobile screens */
  @media (max-width: 768px) {
    display: none;
  }
`;

// New MobileLeftSection styled component
export const MobileLeftSection = styled.div`
  display: none;
  background: #a70101;
  width: 50px;
  height: 100vh;

  /* Display only on mobile screens */
  @media (max-width: 768px) {
    display: block;
  }
`;

export const RightSection = styled.div`
  flex: 0 0 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  /* Take full width on mobile screens */
  @media (max-width: 768px) {
    flex: 1;
    width: calc(100% - 100px); /* Adjust width for mobile left section */
  }
`;
export const Intro = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 320px;
  width: 400px;
  border-radius: 20px;
  backdrop-filter: blur(19.1px);
  border: 3px solid #c55151;

  background: linear-gradient(
    133.63deg,
    rgba(255, 255, 255, 0.4) 1%,
    rgba(255, 255, 255, 0) 100%
  );
  color: white;
  padding: 25px;

  h1 {
    font-weight: 700;
    font-size: 40px;
  }

  /* Hide LeftSection on mobile screens */
  @media (min-width: 769px) and (max-width: 900px) {
    height: 30vh;
    width: 35vw;

    h1 {
      font-size: clamp(12px, 3vw, 40px);
    }
  }

  /* Take full width on mobile screens */
  @media (max-width: 768px) {
    flex: 1;
    width: 100%;
  }
`;
export const LoginCard = styled.div`
  padding: 40px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;

  form {
    display: flex;
    flex-direction: column;
    column-gap: 5px;
    width: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  input {
    width: 95% !important;
    height: 35px !important;
    border: 1px solid #a70101 !important;
    border-radius: 8px !important;
    outline: none;
    background: #9b9b9b;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  input::placeholder {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  input:focus {
    border: 1px solid #a70101 !important;
    height: 35px !important;
    outline: none !important;
  }

  button {
    width: 95%;
    background: #a70101;
    color: #fff;
    border: none;
    cursor: pointer;
    height: 35px;
    border-radius: 8px;
  }

  .registerButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    width: 95%;
    color: black;
    border: 1px solid #a70101;
    cursor: pointer;
    height: 35px;
    border-radius: 8px;
  }

  .flex-properties {
    align-items: center;
    gap: 0.5rem !important;
  }

  .auth-main-text {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-family: Poppins;

    .login-span {
      font-size: clamp(22px, 2vw, 28px);

      font-weight: 600;
      line-height: 48px;

      /* Take full width on mobile screens */
      @media (max-width: 768px) {
        font-size: clamp(14px, 5vw, 28px);
      }
    }

    .reg-span {
      font-size: clamp(22px, 2vw, 25px);

      font-weight: 600;
      line-height: 48px;

      /* Take full width on mobile screens */
      @media (max-width: 768px) {
        font-size: clamp(14px, 5vw, 25px);
      }
    }
  }
`;

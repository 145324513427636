import styled, { css } from "styled-components";

import { Link } from "react-router-dom";
import { GlobalRoutes } from "../../../../../routes/GlobalRoutes";
import Logo from "../../../../../static/img/camzify.png";

const StyledComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  height: 100px;
  margin: 0;
  background-color: ${(props) => (props.theme === "light" ? "white !important" : "rgba(50, 67, 97, 1) !important")};
  a {
    text-decoration: none;
  }

  span {
    font-family: "Poppins";
    font-size: 44px;
    font-weight: 700;
    color: white;
    font-size: 20px;
  }
  i {
    position: relative;
    right: -53px;
    cursor: pointer;
    color: #05a431;
  }

  .fa-lg {
    font-size: 1.8rem !important;
  }
`;

export const NavigationBrandContainerMobile = ({ mobileToggle, theme }) => {
  return (
    <StyledComponent theme={theme}>
      <Link to={GlobalRoutes.pathDefinitions.account.dashboard}>
        <img src={Logo} alt="logo" width={130} />
      </Link>
      <i className="fas fa-chevron-circle-left fa-lg" onClick={() => mobileToggle(false)}></i>
    </StyledComponent>
  );
};

import React from "react";

const ChartThreeSvg = ({ fillColor = "black" }) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 10H8C7.44781 10 7 10.4478 7 11V15C7 15.5522 7.44781 16 8 16H12C12.5522 16 13 15.5522 13 15V11C13 10.4478 12.5522 10 12 10ZM6 1C6 0.447812 5.55219 0 5 0H1C0.447812 0 0 0.447812 0 1V5C0 5.55219 0.447812 6 1 6H3.99125L6.2775 10.0013C6.62438 9.40563 7.2625 9 8 9H8.00875L6 5.48469V4H13V2H6V1ZM19 0H15C14.4478 0 14 0.447812 14 1V5C14 5.55219 14.4478 6 15 6H19C19.5522 6 20 5.55219 20 5V1C20 0.447812 19.5522 0 19 0Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ChartThreeSvg;

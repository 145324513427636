import styled from "styled-components";

import Logo from "../../../../static/img/camzify.png";
import { useEffect, useState } from "react";
import SmallLogo from "../../../svgTojsx/SmallLogo";

const StyledComponent = styled.div`
  width: ${({ width }) => width || "100%"};
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  .br-btm {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d0d0d0;
    width: 75%;
    height: 100%;
    padding: 1rem 0;
    margin: 0;
  }

  background-color: ${(props) =>
    props.theme === "light" ? "white" : "#263754"} !important;

  .toggleBtn {
    position: absolute;
    left: ${(props) => (props.isDesktop ? "88px" : "238px")};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: ${(props) =>
      props.theme === "light" ? "#A70101" : "#1A2944"} !important;
    color: white;
    border-radius: 5px;

    span {
      font-size: 25px;
      color: white;
      cursor: pointer;
    }
  }
`;

export const NavigationBrandContainer = ({ ...props }) => {
  const storedIsDesktop = localStorage.getItem("isDesktop");
  const [isDesktop, setIsDesktop] = useState(storedIsDesktop === "true");

  const toggleDesktop = () => {
    setIsDesktop(!isDesktop);
    props.setIsDesktopToggle(!isDesktop);
  };

  useEffect(() => {
    localStorage.setItem("isDesktop", isDesktop);
  }, [isDesktop]);

  return (
    <StyledComponent {...props} isDesktop={isDesktop}>
      <div className='br-btm'>
        {!isDesktop ? <img src={Logo} alt='logo' width={170} /> : SmallLogo()}

        <div className='toggleBtn'>
          <span onClick={() => toggleDesktop()}>{isDesktop ? ">" : "<"}</span>
        </div>
      </div>
    </StyledComponent>
  );
};

function LiveStreamIcon(mobile = true) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g stroke="#787878" strokeLinecap="round" strokeLinejoin="round" clipPath="url(#clip0_868_42)">
        <path
          strokeWidth="3"
          d="M22 11.89v.89c0 3.56-.89 4.44-4.44 4.44H6.44c-3.55 0-4.44-.89-4.44-4.44V6.44C2 2.89 2.89 2 6.44 2H8m4 15.22V22M2 13h20M7.5 22h9"
        ></path>
        <path
          strokeWidth="2"
          d="M17.86 9.37H13.1c-1.38 0-1.84-.92-1.84-1.84V4.01c0-1.1.9-2 2-2h4.6c1.02 0 1.84.82 1.84 1.84v3.68c0 1.02-.82 1.84-1.84 1.84zm3.05-1.45l-1.21-.85V4.31l1.21-.85c.6-.41 1.09-.16 1.09.57v3.33c0 .73-.49.98-1.09.56z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_868_42">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LiveStreamIcon;

import React from "react";

const LightTheme = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      fill='none'
      viewBox='0 0 28 30'
    >
      <path
        stroke='#A70101'
        strokeWidth='2'
        d='M12.886.895Zm0 0 .019.003zM12.86.89l.006-.012zm.006 0 .016.003zM6.416 3.71a13.3 13.3 0 0 1 6.143-2.66 12.4 12.4 0 0 0-2.403 3.831 12.7 12.7 0 0 0-.893 4.806 12.86 12.86 0 0 0 1.619 6.16 12.4 12.4 0 0 0 4.358 4.55 11.9 11.9 0 0 0 5.98 1.742 11.86 11.86 0 0 0 5.633-1.315 14 14 0 0 1-4.142 5.338 13.3 13.3 0 0 1-6.494 2.732 13.1 13.1 0 0 1-6.937-.998 13.63 13.63 0 0 1-5.537-4.47 14.35 14.35 0 0 1-2.641-6.758c-.292-2.457.047-4.95.982-7.225a14 14 0 0 1 4.333-5.733Zm6.46-2.846.02-.036z'
      ></path>
    </svg>
  );
};

export default LightTheme;

import * as React from "react";

const ForgotPasswordSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    fill="none"
    viewBox="0 0 17 17"
  >
    <path
      fill="#A70101"
      d="M16.575 8.11a8 8 0 1 1-16 0 8 8 0 0 1 16 0m-8.926 4.236 5.936-5.935a.516.516 0 0 0 0-.73l-.73-.73a.516.516 0 0 0-.73 0l-4.84 4.84-2.26-2.26a.516.516 0 0 0-.73 0l-.73.73a.516.516 0 0 0 0 .73l3.354 3.355a.516.516 0 0 0 .73 0"
    ></path>
  </svg>
);

export default ForgotPasswordSvg;

import "../../styles/account_auth.css";

import { useContext, useState } from "react";
import { GlobalUserProfileContext } from "../../App";
import { MainWrapper } from "../MainWrapper";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FilterContainer } from "../global/filters/Container";
import Features from "./components/dashbaord/filters/Features";
import DashboardChart from "./components/dashbaord/filters/DashboardChart";
import NotificationsCards from "./components/NotificationsCards";
import RecentNotifications from "./components/dashbaord/filters/RecentNotifications";

const Dashboard = () => {
  const { isFetchingProfile: isFetching, profile } = useContext(
    GlobalUserProfileContext
  );
  const [filterData, setFilterData] = useState({});
  const [featureData, setFeatureData] = useState({});

  const { theme } = useSelector((state) => state.themeReducer);

  const handleFilterData = (data) => {
    setFilterData(data);
  };

  return (
    <MainWrapper
      parent="account"
      isFetching={isFetching}
      profile={profile}
      pageTitle="Dashboard"
    >
      <StyledComponent theme={theme}>
        <div
          className={`tab-content-header d-flex ${
            theme === "dark" &&
            "tab-content-header-dark-bg tab-content-header-dark-text"
          }`}
        >
          <div className="filters-content">
            <Features setterCallback={setFeatureData} />
            <div className="d-flex gap-4">
              <FilterContainer
                componentName={"FilterByUserSiteCamera"}
                setterCallback={handleFilterData}
                liveSearch={true}
                defaultValues={null}
              />

              <div className="search-add">
                <button
                  className={`navbarDwn ${
                    theme === "dark" && "navbarDwn-dark"
                  }`}
                >
                  Refresh
                </button>
              </div>
            </div>
          </div>
        </div>

        <NotificationsCards />

        <div className="dashboard-content">
          <DashboardChart />
          <RecentNotifications />
        </div>
      </StyledComponent>
    </MainWrapper>
  );
};

export default Dashboard;

const StyledComponent = styled.div`
  margin: 1.3rem;

  .filters-content {
    span {
      color: ${(props) =>
        props.theme === "dark" ? "white !important" : "black !important"};
    }

    .react-select-single__option {
      span {
        text-overflow: "ellipsis";
      }
    }
    .react-select-single-container {
      span {
        display: none;
      }
      .react-select-single__loading-indicator {
        margin-right: 0px !important;
        padding: 0px !important;

        span {
          display: block !important;
        }
      }
    }
  }

  .dashboard-content {
    display: flex;
    gap: 1rem; /* Optional: Adds spacing between the components */
    padding: 1rem;

    /* Ensure each child takes up half the width */
    > div {
      flex: 1;
      width: 50%;
    }
  }
`;

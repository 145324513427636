import * as React from "react";

const DownArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="9"
    fill="none"
    viewBox="0 0 14 9"
  >
    <path
      fill="#A70101"
      d="M6.26 8.693.31 2.768a1.04 1.04 0 0 1 0-1.476l.989-.985a1.05 1.05 0 0 1 1.483 0L7 4.507l4.219-4.2a1.05 1.05 0 0 1 1.483 0l.99.985c.41.41.41 1.071 0 1.476L7.74 8.693c-.403.41-1.068.41-1.48 0"
    ></path>
  </svg>
);

export default DownArrow;

import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { FooterContainer } from "./global/footer/Container";
import { NavigationBrandContainer } from "./global/navigation/brand/Container";
import { NavigationLinksContainer } from "./global/navigation/links/Container";
import { UserProfile } from "./global/containers/userprofile/UserProfile";

const SideBarLeft = ({ profile, theme }) => {
  // Retrieve the value from localStorage on component mount
  const storedDesktopToggle = localStorage.getItem("desktopToggle");
  const [desktopToggle, setDesktopToggle] = useState(
    storedDesktopToggle === "true"
  );

  // Update localStorage whenever desktopToggle changes
  useEffect(() => {
    localStorage.setItem("desktopToggle", desktopToggle);
  }, [desktopToggle]);

  return (
    profile && (
      <StyledComponent desktopToggle={desktopToggle} theme={theme}>
        <NavigationBrandContainer
          desktopToggle={desktopToggle}
          theme={theme}
          setIsDesktopToggle={setDesktopToggle}
        />
        <UserProfile theme={theme} desktopToggle={desktopToggle}></UserProfile>

        <NavigationLinksContainer
          account_type={profile?.account_type}
          profile={profile}
          desktopToggle={desktopToggle}
          theme={theme}
        />
        <FooterContainer desktopToggle={desktopToggle} />
      </StyledComponent>
    )
  );
};

export default SideBarLeft;

const StyledComponent = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  background: ${(props) =>
    props.theme === "light"
      ? "white"
      : "linear-gradient(180deg, rgba(38, 55, 84, 1), rgba(22, 32, 49, 1))"};

  max-width: 250px;
  min-width: 250px;
  min-height: 100vh;
  color: white;
  overflow: auto;
  transition: width 0.7s ease, max-width 0.7s ease, min-width 0.7s ease;

  ${({ desktopToggle }) =>
    desktopToggle &&
    css`
      max-width: 100px;
      min-width: 100px;
      align-itmes: center;
      justify-content: center;
    `}

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

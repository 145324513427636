import styled, { css } from "styled-components";

const StyledComponents = styled.div`
  padding: 1.5rem 0rem;
  font-family: var(--font-1);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
  justify-self: flex-end;

  .deutics-link {
    text-decoration: none;
    color: #00000080;
    font-weight: bold;
    &:hover {
      color: #a70101;
    }
  }
  .copyrights {
    color: #00000080;
    font-family: "Poppins";
    text-align: center;
    font-size: 13px;
    font-weight: 200;
    margin-top: 0.5rem;
  }

  ${({ desktopToggle }) =>
    desktopToggle &&
    css`
      .nav-label {
        display: none;
      }
    `}
`;

export const FooterContainer = ({ desktopToggle }) => {
  return (
    <StyledComponents desktopToggle={desktopToggle}>
      <span className='copyrights'>
        Powered by{" "}
        <a
          href='https://deutics.com/'
          target='_blank'
          rel='noopener noreferrer'
          className='deutics-link'
        >
          Deutics Global
        </a>
      </span>
    </StyledComponents>
  );
};

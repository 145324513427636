import React from "react";

const ChartTwoSvg = ({ fillColor = "black" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4 10.6667H11.6C11.8 10.6667 12 10.4 12 10.1333V4.53333C12 4.26667 11.8 4 11.6 4H10.4C10.2 4 10 4.26667 10 4.53333V10.1333C10 10.4 10.2 10.6667 10.4 10.6667ZM13.4 10.6667H14.6C14.8 10.6667 15 10.4 15 10.1333V0.533334C15 0.266667 14.8 0 14.6 0H13.4C13.2 0 13 0.266667 13 0.533334V10.1333C13 10.4 13.2 10.6667 13.4 10.6667ZM4.4 10.6667H5.6C5.8 10.6667 6 10.4 6 10.1333V7.2C6 6.93333 5.8 6.66667 5.6 6.66667H4.4C4.2 6.66667 4 6.93333 4 7.2V10.1333C4 10.4 4.2 10.6667 4.4 10.6667ZM7.4 10.6667H8.6C8.8 10.6667 9 10.4 9 10.1333V1.86667C9 1.6 8.8 1.33333 8.6 1.33333H7.4C7.2 1.33333 7 1.6 7 1.86667V10.1333C7 10.4 7.2 10.6667 7.4 10.6667ZM15.5 13.3333H2V0.666667C2 0.298333 1.77625 0 1.5 0H0.5C0.22375 0 0 0.298333 0 0.666667V14.6667C0 15.4029 0.447812 16 1 16H15.5C15.7762 16 16 15.7017 16 15.3333V14C16 13.6317 15.7762 13.3333 15.5 13.3333Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ChartTwoSvg;

import "../../styles/account_auth.css";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axiosInstance from "../../config/axios";
import displayError from "../../helpers/displayError";
import Spinner from "../Spinner";
import { fetchProfile } from "../../config/actions";
import { useDispatch, useSelector } from "react-redux";
import APIEndpoints from "../../config/api/endpoints";
import { AuthUtilsHelper } from "../../helpers/utils/auth";
import TriImg from "../../static/img/Polygon 6.png";
import Logo from "../../static/img/camzify.png";
import {
  Intro,
  LeftSection,
  MobileLeftSection,
  LoginCard,
  LoginContainer,
  RightSection,
} from "./styledComponent";

const Login = () => {
  const { theme } = useSelector((state) => state.themeReducer);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();

  const handleLogin = async () => {
    setProcessing(true);
    const requestPayload = {
      login: username,
      password,
    };
    (await axiosInstance())
      .post(APIEndpoints.auth.me.login(), requestPayload)
      .then((response) => {
        const { refresh, access } = response.data;
        window.localStorage.setItem("access_token", access);
        window.localStorage.setItem("refresh_token", refresh);
        AuthUtilsHelper.clearRelationAuth();
        setProcessing(false);
        dispatch(fetchProfile);
        navigate("/account/dashboard", { replace: true });
      })
      .catch((err) => {
        displayError(err, theme);
        setProcessing(false);
      });
  };

  return (
    <LoginContainer>
      <LeftSection>
        <Intro>
          <h1>
            SECURE & INTELLIGENT <br />
            <span>
              <img
                src={TriImg}
                alt='triangle'
                style={{ marginRight: "10px" }}
                height={30}
              />
            </span>
            SURVEILLANCE
          </h1>
          <p>
            Camzify is a robust desktop application designed for secure and
            efficient surveillance management.
          </p>
        </Intro>
      </LeftSection>

      {/* Show MobileLeftSection on small screens */}
      <MobileLeftSection />

      <RightSection>
        <LoginCard>
          <div className='d-flex flex-column column-gap-5'>
            <img src={Logo} alt='logo' />
            <div className='auth-main-text'>
              <span className='login-span'>Login to your Account </span>
              <span className='login-desc'>
                Access your account securely to manage your settings and view
                your data.{" "}
              </span>
            </div>
          </div>
          <form>
            <input
              disabled={processing}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type='text'
              className={`form-control form-control-custom mt-3 ${
                theme === "dark" && "form-control-custom-dark"
              }`}
              id='username'
              aria-describedby='emailHelp'
              placeholder='Email'
            />

            <input
              disabled={processing}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type='password'
              className={`form-control form-control-custom mt-3 ${
                theme === "dark" && "form-control-custom-dark"
              }`}
              id='password'
              placeholder='Password'
            />

            {processing ? (
              <div className='m-3'>
                <Spinner size={1.2} rot_time={0.5} />{" "}
              </div>
            ) : (
              <button
                disabled={processing}
                type='submit'
                onClick={(e) => {
                  e.preventDefault();
                  handleLogin();
                }}
                className='m-3'
              >
                Login
              </button>
            )}
          </form>
          <div className='flex-properties'>
            <div>
              {!processing && (
                <Link
                  to='/forgot_password'
                  className={theme === "dark" && "text-light"}
                >
                  Forgot Password?
                </Link>
              )}
            </div>
            <div
              onClick={() => navigate("/register")}
              className='registerButton'
            >
              Register
            </div>
          </div>
        </LoginCard>
      </RightSection>
    </LoginContainer>
  );
};

export default Login;

import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { GlobalVideoBackupContext } from "./VideoBackup";

// Context to store video players
export const VideoControlContext = createContext();

export const VideoControlProvider = ({ children }) => {
  const videoPlayersRef = useRef([]);
  const playerStatuses = useRef([]); // Store each player's buffering status
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const { setPlaying, setIsBuffering } = useContext(GlobalVideoBackupContext);

  // Utility function to safely play or pause a player
  const safeAction = useCallback(async (player, action) => {
    try {
      await player[action]();
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error(`Failed to ${action} player:`, error);
      }
    }
  }, []);

  // Add player with optimized event listeners
  const addPlayer = useCallback(
    (player) => {
      videoPlayersRef.current.push(player);
      playerStatuses.current.push(false); // Initially buffering

      player.on("loadedmetadata", () => {
        setDuration((prevDuration) =>
          Math.max(prevDuration, player.duration())
        );
        player.currentTime(currentTime); // Start each player at the global currentTime
      });

      player.on("timeupdate", () => {
        if (!playerStatuses.current.includes(false)) setPlaying(true);
        const averageTime =
          videoPlayersRef.current.reduce((sum, p) => sum + p.currentTime(), 0) /
          videoPlayersRef.current.length;
        setCurrentTime(averageTime);

        const index = videoPlayersRef.current.indexOf(player);
        if (index > -1) playerStatuses.current[index] = true; // Mark player as playing
        if (!playerStatuses.current.includes(false)) setPlaying(true); // All players are ready
      });

      player.on("waiting", () => {
        const index = videoPlayersRef.current.indexOf(player);
        if (index > -1) playerStatuses.current[index] = false; // Mark player as buffering
        setPlaying(false);
        setIsBuffering(true);
      });

      // Attempt initial playback
      safeAction(player, "play");
    },
    [currentTime, setPlaying, safeAction]
  );

  const removePlayer = useCallback((player) => {
    const index = videoPlayersRef.current.indexOf(player);
    if (index > -1) {
      videoPlayersRef.current.splice(index, 1);
      playerStatuses.current.splice(index, 1);
    }
  }, []);

  // Global control functions with safeAction utility
  const playAll = useCallback(() => {
    videoPlayersRef.current.forEach((player) => safeAction(player, "play"));
  }, [safeAction]);

  const pauseAll = useCallback(() => {
    videoPlayersRef.current.forEach((player) => safeAction(player, "pause"));
  }, [safeAction]);

  const forwardAll = useCallback(
    (seconds = 10) => {
      const newTime = currentTime + seconds;
      videoPlayersRef.current.forEach((player) => player.currentTime(newTime));
      setCurrentTime(newTime);
    },
    [currentTime]
  );

  const backwardAll = useCallback(
    (seconds = 10) => {
      const newTime = Math.max(0, currentTime - seconds); // Ensure no negative time
      videoPlayersRef.current.forEach((player) => player.currentTime(newTime));
      setCurrentTime(newTime);
    },
    [currentTime]
  );

  // Seek videos when clicking on the timeline
  const handleTimelineClick = useCallback(
    (e, timelineRef) => {
      const rect = timelineRef.current.getBoundingClientRect();
      const clickPosition = e.clientX - rect.left;
      const newTime =
        (clickPosition / timelineRef.current.scrollWidth) * duration;

      videoPlayersRef.current.forEach((player) => player.currentTime(newTime));
      setCurrentTime(newTime);
    },
    [duration]
  );

  return (
    <VideoControlContext.Provider
      value={{
        addPlayer,
        removePlayer,
        playAll,
        pauseAll,
        forwardAll,
        backwardAll,
        handleTimelineClick,
        currentTime,
        duration,
      }}
    >
      {children}
    </VideoControlContext.Provider>
  );
};

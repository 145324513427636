import React from "react";

const BellIcon = ({ badge = false, theme }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 35 35">
      <g clipPath="url(#clip0_856_70)">
        <path
          fill={theme === "light" ? "none" : "#5E84C3"}
          stroke="#a70101"
          strokeWidth={1.8}
          d="M29.167 27.223l.583.777a.729.729 0 01-.583 1.167H5.833A.73.73 0 015.25 28l.583-.777v-12.64a11.667 11.667 0 0123.334 0v12.64zm-15.313 3.402h7.292a3.646 3.646 0 11-7.292 0z"
        ></path>
      </g>
      {badge && <circle cx="26.5" cy="7.5" r="4.5" fill="#05A431"></circle>}

      <defs>
        <clipPath id="clip0_856_70">
          <path fill="#fff" d="M0 0H35V35H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default BellIcon;

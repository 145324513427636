import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import DownArrow from "../../../svgTojsx/DownArrow";

// Styled components
const DropdownContainer = styled.div`
  position: relative;
  width: 161px;
`;

// Add this style to your styled components
const StyledSelect = styled.div`
  border: 1px solid ${({ theme }) => (theme === "dark" ? "#A70101" : "#A70101")};
  background-color: ${({ theme }) =>
    theme === "dark" ? "rgba(38, 56, 84, 1)" : ""};
  border-radius: 5px;
  padding: 8px;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px; /* Updated font size */
`;

const SpanContainer = styled.div`
  font-size: 14px !important; /* Updated font size */
  font-weight: 300;
`;

const SpanIcon = styled.div`
  font-size: 14px !important; /* Updated font size */
  font-weight: 300;
  color: #a70101;
`;

const OptionsList = styled.div`
  position: absolute;
  background-color: ${({ theme }) =>
    theme === "dark" ? "rgba(38, 56, 84, 1)" : "white"};
  border-radius: 15px;
  width: 100%;
  z-index: 1;
  margin-top: 5px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 0px 10px #00000040;
  display: flex;
  flex-direction: column;
  font-size: 14px; /* Updated font size */
  padding: 0.5rem 0;
  border: 1px solid #a70101;
`;

const OptionContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Keep checkbox on the left, text centered */
  padding: 10px;
  cursor: pointer;
  border-bottom: ${({ theme }) =>
    theme === "dark" ? "2px solid #A70101 " : "2px solid #A70101"} !important;
  font-size: 12px !important;
  font-weight: 400;
  transition: background-color 0.3s ease;

  &&& span {
    font-size: 12px !important;
    font-weight: 300;
    text-align: center; /* Center the text within its space */
    flex-grow: 1; /* Ensures the text takes up the remaining space */
  }

  .idDOGq span {
    font-size: 12px !important;
  }

  &:hover {
    background-color: #ffe0e0;
    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: 20%;
      bottom: 0;
      height: 20px;
      width: 7px;
      border-radius: 5px 0 0 5px; // Apply border-radius on the right side
      background-color: #a70101;
    }
  }
`;

const OptionShow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  border-bottom: ${({ theme }) =>
    theme === "dark" ? "2px solid #A70101" : "2px solid #A70101"} !important;

  &:hover {
    background-color: #ffe0e0;
    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: 20%;
      bottom: 0;
      height: 20px;
      width: 7px;
      border-radius: 5px 0 0 5px; // Apply border-radius on the right side
      background-color: #a70101;
    }
  }

  span {
    font-size: 12px !important; /* Updated font size */
    font-weight: 400;
  }
`;

const CustomCheckbox = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #a70101;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  background-color: ${({ checked }) => (checked ? "#A70101" : "transparent")};
  cursor: pointer;
  position: relative; /* Added to position the tick */

  /* Tick mark styles */
  &:after {
    content: "";
    position: absolute;
    width: 6px; /* Width of the tick */
    height: 12px; /* Height of the tick */
    border: solid white; /* Color of the tick */
    border-width: 0 2px 2px 0; /* Create a right-angled tick */
    transform: rotate(45deg); /* Rotate to form a tick */
    display: ${({ checked }) =>
      checked ? "block" : "none"}; /* Show or hide */
  }
`;

const formatOptionValue = (value) => {
  const words = value.split(" ");
  if (words.length > 2) {
    const truncatedThirdWord = words[2].charAt(0) + "...";
    return `${words[0]} ${words[1]} ${truncatedThirdWord}`;
  }
  return value;
};

export const SelectComponent = ({
  selectedOptions,
  optionType,
  handleOptionChange,
  optionData,
}) => {
  const { theme } = useSelector((state) => state.themeReducer);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference for the dropdown container

  const hasOptions = optionData[optionType]?.data?.length > 0;

  const toggleDropdown = () => {
    if (hasOptions) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleOptionClick = (option) => {
    const newSelectedOptions = selectedOptions[optionType].includes(option)
      ? selectedOptions[optionType].filter((opt) => opt !== option)
      : [...selectedOptions[optionType], option];

    handleOptionChange(optionType, newSelectedOptions);
    setIsOpen(false); // Close dropdown on option select
  };

  const handleSelectAll = () => {
    if (
      selectedOptions[optionType].length === optionData[optionType]?.data.length
    ) {
      handleOptionChange(optionType, []);
    } else {
      handleOptionChange(optionType, optionData[optionType]?.data);
    }
  };

  // Function to close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Adding and removing event listener for outside clicks
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <StyledSelect onClick={toggleDropdown} isDisabled={!hasOptions}>
        <SpanContainer>{optionType}</SpanContainer>
        <SpanIcon>{isOpen ? "▲" : "▼"}</SpanIcon>
      </StyledSelect>
      {isOpen && (
        <OptionsList theme={theme}>
          <OptionContainer onClick={handleSelectAll}>
            <CustomCheckbox
              checked={
                selectedOptions[optionType].length ===
                optionData[optionType]?.data.length
              }
            />
            <span>All</span>
          </OptionContainer>
          {optionData[optionType]?.data.map((option) => (
            <OptionShow
              key={option.value}
              onClick={() => handleOptionClick(option)}
            >
              <CustomCheckbox
                checked={selectedOptions[optionType].includes(option)}
                onClick={() => handleOptionClick(option)}
              />
              <span>{formatOptionValue(option.value)}</span>
              <span style={{ color: "rgb(147,155,170)" }}>{option.count}</span>
            </OptionShow>
          ))}
        </OptionsList>
      )}
    </DropdownContainer>
  );
};

// import React from "react";
// import { useSelector } from "react-redux";
// import Select from "react-select";

// const customStyles = (theme, isLoading) => ({
//   control: (provided, state) => ({
//     ...provided,
//     boxShadow: state.isFocused ? null : provided.boxShadow,
//     borderWidth: "1px",
//     borderColor:
//       theme === "dark" ? "rgba(94, 132, 195, 1)" : "rgb(37, 68, 120)",
//     borderRadius: "30px",
//     fontWeight: 300,
//     minHeight: "10px",
//     fontSize: "12px",
//     lineHeight: "16px",
//     cursor: "pointer",
//     outline: "none",
//     background: "transparent",
//     height: "33px !important",
//     width: "200px",
//   }),
//   option: (provided) => ({
//     ...provided,
//     outline: "none",
//     backgroundColor: "transparent",
//     color: theme === "dark" ? "white!important" : "black!important",
//     cursor: "pointer",
//     overflowY: "hidden",
//   }),

//   noOptionsMessage: (provided) => ({
//     ...provided,
//     color: theme === "dark" ? "white" : "black",
//   }),

//   indicatorSeparator: (provided, state) => ({
//     ...provided,
//     display: "none",
//   }),

//   dropdownIndicator: (provided, state) => ({
//     ...provided,
//     color: theme === "dark" ? "rgba(94, 132, 195, 1)" : "rgb(37, 68, 120)",
//     padding: "6px",
//   }),

//   placeholder: (provided, state) => ({
//     ...provided,
//     color: theme === "dark" ? "white" : "black",
//   }),
//   valueContainer: (provided, state) => ({
//     padding: "0px 8px",
//     display: "flex",
//     alignItems: "center",
//     width: !isLoading && "130px",
//     overflow: "scroll",
//     overflowY: "hidden",
//   }),
//   multiValue: (provided, state) => ({
//     ...provided,
//     minWidth: "auto",
//     background: "transparent",
//     color: theme === "dark" ? "white!important" : "black!important",
//     fontSize: "0.9rem!important",
//   }),
//   multiValueGeneric: (provided, state) => ({
//     ...provided,
//     minWidth: "auto",
//     background: "transparent",
//     color: theme === "dark" ? "white!important" : "black!important",
//     "& span": {
//       fontSize: "0.9rem!important",
//     },
//   }),
//   input: (provided, state) => ({
//     ...provided,
//     margin: "-7px",
//   }),
//   indicatorContainer: (provided, state) => ({
//     padding: "0px",
//   }),
//   singleValue: (provided, state) => ({
//     ...provided,
//     outline: "none",
//     color: theme === "dark" ? "white!important" : "black!important",
//     background: state.isFocused ? "white" : "white",
//   }),
//   loadingIndicator: (provided, state) => ({
//     ...provided,
//     padding: "0px!important",
//     marginRight: "0px!important",
//   }),
//   loadingDot: (provided, state) => ({
//     ...provided,
//     width: "5px",
//     height: "5px",
//     fontSize: "0.5rem",
//     display: "inline-block!improtant",
//   }),

//   menu: (provided) => ({
//     ...provided,
//     boxShadow:
//       theme === "dark"
//         ? "0px 4px 9px 0px rgba(0, 0, 0, 0.25)"
//         : "0px 4px 9px 0px rgba(0, 0, 0, 0.25)",
//     background: theme === "dark" ? "rgba(38, 56, 84, 1)" : "white",
//     borderRadius: "16px",
//   }),
//   menuList: (base) => ({
//     ...base,

//     "::-webkit-scrollbar": {
//       display: "none",
//     },
//   }),
// });

// export const SelectComponent = ({
//   selectedOptions,
//   optionType,
//   handleOptionChange,
//   optionData,
//   handleScroll,
// }) => {
//   const { theme } = useSelector((state) => state.themeReducer);

//   return (
//     <>
//       {optionData && (
//         <Select
//           value={selectedOptions[optionType]}
//           onChange={(selectedOption) =>
//             handleOptionChange(optionType, selectedOption)
//           }
//           className='react-select-container'
//           classNamePrefix='react-select'
//           options={optionData[optionType]?.data}
//           placeholder={optionType}
//           isSearchable={true}
//           isClearable={true}
//           onMenuScrollToBottom={() => handleScroll(optionType)}
//           styles={customStyles(theme, optionData[optionType]?.isLoading)}
//           isMulti
//           closeMenuOnSelect={false}
//           isOptionDisabled={(option) => option.count <= 0}
//           getOptionLabel={(option, index) => (
//             <div
//               key={index}
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 gap: "0.8rem",
//               }}
//             >
//               <span style={{ display: "block" }}>{option.value}</span>
//               <div style={{ color: "rgb(147,155,170" }}>{option.count}</div>
//             </div>
//           )}
//           // Disable the dropdown when it's loading
//           isDisabled={optionData[optionType]?.data.length > 0 ? false : true}
//           isLoading={optionData[optionType]?.isLoading}
//         />
//       )}
//     </>
//   );
// };

import "../../styles/account_auth.css";
import { useSelector } from "react-redux";
import { useState } from "react";
import axiosInstance from "../../config/axios";
import APIEndpoints from "../../config/api/endpoints";
import { useNavigate, useLocation } from "react-router-dom";
import displayError from "../../helpers/displayError";
import Spinner from "../Spinner";
import styled from "styled-components";
import TriImg from "../../static/img/Polygon 6.png";
import Logo from "../../static/img/camzify.png";
import ForgotPasswordSvg from "../../static/svg/ForgotPasswordSvg";
import {
  Intro,
  LeftSection,
  LoginCard,
  LoginContainer,
  MobileLeftSection,
  RightSection,
} from "./styledComponent";

const VerifyCode = () => {
  const { theme } = useSelector((state) => state.themeReducer);
  const [isInitiating, setIsInitiating] = useState(false);
  const [btnText, setBtnText] = useState("Verify");
  const [verificationCode, setVerificationCode] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state;

  const handleSubmit = async () => {
    setIsInitiating(true);

    const requestPayload = {
      verify: true,
      email: email,
      verification_code: parseInt(verificationCode, 10),
    };

    try {
      const response = await (
        await axiosInstance()
      ).post(APIEndpoints.auth.me.finalizeForgotPassword(), requestPayload);
      setBtnText("Verify Code");
      setSuccessMessage("A code has been verified successfully");
      setIsInitiating(false);

      setTimeout(() => {
        navigate("/reset_password", { state: email });
      }, 2000);
    } catch (err) {
      displayError(err, theme);
      setIsInitiating(false);
    }
  };

  return (
    <LoginContainer>
      <LeftSection>
        <Intro>
          <h1>
            SECURE & INTELLIGENT <br />
            <span>
              <img
                src={TriImg}
                alt="triangle"
                style={{ marginRight: "10px" }}
                height={30}
              />
            </span>
            SURVEILLANCE
          </h1>
          <p>
            Camzify is a robust desktop application designed for secure and
            efficient surveillance management.
          </p>
        </Intro>
      </LeftSection>

      {/* Show MobileLeftSection on small screens */}
      <MobileLeftSection />
      <RightSection>
        <LoginCard>
          <div className="d-flex flex-column gap-5">
            <img src={Logo} alt="logo" />
            <div className="d-flex flex-column gap-2">
              <span className="fs-2 fw-bold margin">Verify Code</span>
            </div>
          </div>
          <form>
            <input
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              className={`form-control form-control-custom mt-3 ${
                theme === "dark" && "form-control-custom-dark"
              }`}
              id="verificationCode"
              placeholder="Verification Code"
            />

            <button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className={`${theme === "dark" && "btn-custom-orange-dark"}`}
              disabled={isInitiating}
            >
              {isInitiating ? <Spinner size={1.2} rot_time={0.5} /> : btnText}
            </button>

            {successMessage && (
              <SuccessMessage>
                <ForgotPasswordSvg /> {successMessage}
              </SuccessMessage>
            )}
          </form>
        </LoginCard>
      </RightSection>
    </LoginContainer>
  );
};

export default VerifyCode;

const SuccessMessage = styled.div`
  background-color: #fff0f0;
  color: #2d862d;
  font-size: 13px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  margin-top: 15px;
  border-radius: 5px;
  padding: 5px 10px;
`;

// import "../../styles/account_auth.css";
// import SendEmail from "../../static/svg/verify_code.png";
// import { useSelector } from "react-redux";
// import { ThemeComponent } from "../global/toggletheme";
// import { DarkThemeAuth } from "../global/graphical/DarkThemeAuth";
// import { useState } from "react";
// import axiosInstance from "../../config/axios";
// import APIEndpoints from "../../config/api/endpoints";
// import { useLocation, useNavigate } from "react-router-dom";
// import displayError from "../../helpers/displayError";
// import { displayMessage } from "../../helpers/displayMessage";

// const VerifyCode = () => {
//   const { theme } = useSelector((state) => state.themeReducer);
//   const [isInitiating, setIsInitiating] = useState(false);
//   const [verificationCode, setVerificationCode] = useState(null);
//   const navigate = useNavigate();

//   const location = useLocation();
//   const email = location.state;
//   const handleSubmit = async () => {
//     setIsInitiating(true);

//     const requestPayload = {
//       verify: true,
//       email: email,
//       verification_code: parseInt(verificationCode, 10),
//     };
//     (await axiosInstance())
//       .post(APIEndpoints.auth.me.finalizeForgotPassword(), requestPayload)
//       .then((response) => {
//         displayMessage(response.data.msg);
//         navigate("/reset_password", { state: email });
//         setIsInitiating(false);
//       })
//       .catch((err) => {
//         displayError(err, theme);
//         setIsInitiating(false);
//       });
//   };

//   return (
//     <div
//       id="body-2"
//       style={
//         theme === "dark"
//           ? {
//               background: `linear-gradient(
// to right bottom,
// rgb(32, 45, 66),
// rgb(22, 33, 49),
// rgb(20, 45, 49)`,
//               flexDirection: "column",
//               justifyContent: "space-between",
//             }
//           : {
//               flexDirection: "column",
//               justifyContent: "space-between",
//             }
//       }
//     >
//       <div className="toggleBtnAuth">
//         <ThemeComponent />
//       </div>
//       <DarkThemeAuth>
//         <div className={`main-frame-small ${theme === "dark" && "main-frame-dark"}`}>
//           <div className="form-wrapper" style={{ width: "100%" }}>
//             <form className="d-flex flex-column align-items-start w-100">
//               <div className="desktop mt-5" style={{ width: "100px", height: "100px" }}>
//                 <img src={SendEmail} alt="" className="w-100 h-100," />
//               </div>
//               <div className="d-flex justify-content-center my-5">
//                 <span className={`highlight-heading ${theme === "dark" && "highlight-heading-dark"} text-auth-title`}>
//                   nettbox
//                 </span>
//               </div>

//               <div className="mb-3 w-100">
//                 <label
//                   htmlFor="username"
//                   className={`form-label form-label-custom highlight-text ${theme === "dark" && "highlight-text-dark"}`}
//                 >
//                   Verification Code
//                 </label>
//                 <input
//                   type="number"
//                   onChange={(e) => setVerificationCode(e.target.value)}
//                   className={`form-control form-control-custom mt-3 ${theme === "dark" && "form-control-custom-dark"}`}
//                   id="verificationCode"
//                   aria-describedby="codeHelp"
//                   placeholder="Your code here..."
//                 />
//               </div>
//               <div className="d-flex justify-content-center my-3">
//                 <button
//                   disabled={isInitiating}
//                   type="submit"
//                   onClick={(e) => {
//                     e.preventDefault();
//                     handleSubmit();
//                   }}
//                   className={`btn btn-custom-orange waves-effect waves-light px-5 ${
//                     theme === "dark" && "btn-custom-orange-dark"
//                   }`}
//                 >
//                   Verify
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </DarkThemeAuth>
//     </div>
//   );
// };

// export default VerifyCode;

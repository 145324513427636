import styled from "styled-components";
import { NavigationComponent } from "../containers/navigation/NavigationComponent";
// import { UserProfile } from '../containers/userprofile/UserProfile'

const StyledComponent = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--custom-primary-bg);
  z-index: initial;
  justify-content: space-between;
  flex-grow: 0;
  border-bottom: ${(props) => (props.theme === "light" ? `2px solid rgba(228, 228, 228, 1)` : "2px solid #263854")};

  width: 100%;

  .authMenuBarTitle {
    padding: 1.35rem 3rem;
    margin: 0;
    color: ${(props) => (props.theme === "light" ? `black` : "rgba(94, 132, 195, 1)")};
    font-weight: 500;
    display: flex;
    i {
      display: none;
    }
    span {
      font-family: "Poppins";
      ${"" /* font-size: 30px; */}
      font-size: clamp(12px, 2vw, 20px) !important;
      text-align: left;
      font-weight: 400;
      ${
        "" /* @media (max-width: 1200px) {
                font-size: 22px;
            }
            @media (max-width: 1200px) {
                font-size: 22px;
            }
            @media (max-width: 900px) {
                font-size: 18px;
            }
            @media (max-width: 500px) {
                font-size: 16px;
            }
            @media (max-width: 395px) {
                font-size: 14px;
            } */
      }
    }
    a {
      color: var(--custom-cream-color);
      text-decoration: none;
      cursor: pointer;
    }
    @media (max-width: 800px) {
      padding: 1.35rem 0rem;
      color: ${(props) => (props.theme === "dark" ? `white` : "rgba(37, 68, 120, 1)")};
      span {
        background-color: ${(props) => (props.theme === "light" ? `white` : "#263854")};

        display: flex;
        align-items: center;
        gap: 1rem;
        border-top-right-radius: 27px;
        border-bottom-right-radius: 27px;
        padding: 2px 4px;
        i {
          display: block;
          color: ${(props) => (props.theme === "light" ? `rgba(37, 68, 120, 1)` : "rgba(94, 132, 195, 1)")};
          cursor: pointer;
        }
      }
    }
  }
`;

export const NavigationContainer = ({ pageTitle, setMobileState, theme }) => {
  return (
    <StyledComponent theme={theme}>
      <h2 className="authMenuBarTitle">
        <span className="">
          <i className="fas fa-chevron-circle-right fa-lg" onClick={() => setMobileState(true)}></i> {pageTitle}
        </span>
      </h2>
      <NavigationComponent theme={theme}></NavigationComponent>
    </StyledComponent>
  );
};

import "../../styles/account_auth.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import APIEndpoints from "../../config/api/endpoints";
import axiosInstance from "../../config/axios";
import { displayMessage } from "../../helpers/displayMessage";
import displayError from "../../helpers/displayError";
import { notifyFailure } from "../../helpers/notifications/notifyFailure";
import TriImg from "../../static/img/Polygon 6.png";
import Logo from "../../static/img/camzify.png";
import {
  Intro,
  LeftSection,
  LoginCard,
  LoginContainer,
  MobileLeftSection,
  RightSection,
} from "./styledComponent";
import Spinner from "../Spinner";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isInitiating, setIsInitiating] = useState(false);

  const location = useLocation();
  const email = location.state;
  const handleSubmit = async () => {
    if (newPassword !== password) {
      notifyFailure("Your password fields does not match each other");
      return;
    }
    setIsInitiating(true);

    const requestPayload = {
      verify: false,
      email: email,
      password: password,
      confirm_password: newPassword,
    };
    (await axiosInstance())
      .post(APIEndpoints.auth.me.finalizeForgotPassword(), requestPayload)
      .then((response) => {
        displayMessage(response.data.msg);
        navigate("/login", { replace: true });
        setIsInitiating(false);
      })
      .catch((err) => {
        displayError(err, theme);
        setIsInitiating(false);
      });
  };
  const { theme } = useSelector((state) => state.themeReducer);
  return (
    <LoginContainer>
      <LeftSection>
        <Intro>
          <h1>
            SECURE & INTELLIGENT <br />
            <span>
              <img
                src={TriImg}
                alt='triangle'
                style={{ marginRight: "10px" }}
                height={30}
              />
            </span>
            SURVEILLANCE
          </h1>
          <p>
            Camzify is a robust desktop application designed for secure and
            efficient surveillance management.
          </p>
        </Intro>
      </LeftSection>

      {/* Show MobileLeftSection on small screens */}
      <MobileLeftSection />

      <RightSection>
        <LoginCard>
          <div className='d-flex flex-column gap-5'>
            <img src={Logo} alt='logo' />
            <div className='auth-main-text'>
              <span className={`login-span`}>Reset Your Password</span>
              <span className={`login-desc`}>
                Access your account securely to manage your settings and view
                your data.{" "}
              </span>
            </div>
          </div>

          <form className=''>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type='password'
              className={`form-control form-control-custom mt-3 ${
                theme === "dark" && "form-control-custom-dark"
              }`}
              id='password'
              placeholder='New Password'
            />
            <input
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              type='password'
              className={`form-control form-control-custom mt-3 ${
                theme === "dark" && "form-control-custom-dark"
              }`}
              id='newPassword'
              placeholder='Confirm Password'
            />
            <div className='d-flex align-items-center justify-content-center text-center w-100 mt-3'>
              {isInitiating ? (
                <Spinner size={1.2} rot_time={0.5} />
              ) : (
                <button
                  disabled={isInitiating}
                  type='submit'
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  className={` ${theme === "dark" && "btn-custom-orange-dark"}`}
                >
                  Update
                </button>
              )}
            </div>
          </form>
        </LoginCard>
      </RightSection>
    </LoginContainer>
  );
};

export default ResetPassword;

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { GlobalUserProfileContext } from "../../../../App";
import styled from "styled-components";
import BellIcon from "../../../../static/svg/BellIcon";
import ProfileView from "../../../account/ProfileView";
import { LiveStreamNotificationContainer } from "../../../general/features/livestream/notifications/Container";
import { useLoadDependenciesBeforeRender } from "../../../../helpers/hooks/UseLoadDependenciesBeforeRender";
import { NotificationListItem } from "../../../general/features/livestream/notifications/components/NotificationListItem";
import { notifyToast } from "../../../../helpers/notifications";
import { APIUtils } from "../../../../helpers/utils/api";
import APIEndpoints from "../../../../config/api/endpoints";
import axiosInstance from "../../../../config/axios";
import { WSUtilsHelperConnectionManager } from "../../../../helpers/utils/api/ws";
import { ModalComponent } from "../../../../helpers/utils/Modal";
import { ViewDetailScreen } from "../../../general/features/livestream/notifications/ViewDetailScreen";
import notificationAudio from "../../../general/features/livestream/notifications/media/audio/notification.mp3";
import { useDispatch } from "react-redux";
import { projectsList } from "../../../../config/actions";
import { Link } from "react-router-dom";
import LogoutIcon from "../../../svgTojsx/LogoutIcon";

const ProfilePicture = styled.img`
  max-width: 62px;
  max-height: 62px;
  min-width: 62px;
  min-height: 62px;
  border-radius: 50%;
  object-fit: fill;
  object-position: center;
  background-color: white; /* Ensure a white background in case of missing image */
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem; /* Adjust gap between header and logout section */
`;

const ProfileHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  span {
    font-weight: bold;
  }
`;

const FooterLogout = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  small {
    font-size: 0.9rem;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  width: ${(props) => (props.desktopToggle ? `` : "85%")};
  height: 100%;
  align-items: center;
  gap: 1rem; /* Increased gap between ProfilePicture and ProfileInfo */

  .gradient-bg {
    max-width: 66px;
    min-width: 66px;
    max-height: 66px;
    min-height: 66px;
    display: grid;
    place-items: center;
    background: linear-gradient(133.43deg, #6b0609 1.33%, #f97878 100%);
    border-radius: 50%;
  }
`;
const StyledComponent = styled.div`
    .userProfile {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 1rem;
        padding: 1rem;
        background: ${(props) => (props.theme === "light" ? "" : "#263854")};
        border-radius: 27px;

        span {
            font-size: medium;
            color: ${(props) => (props.theme === "light" ? "black" : "white")};
        }
    }
 

    .dropdown-btn {
        background: ${(props) =>
          props.theme === "light" ? `#254478` : "#5E84C3"};
        border: none;
        display: grid;
        place-items: center;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        cursor: pointer;
    }

    .arrow {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;

        ${(props) =>
          props.theme === "light"
            ? `border-top: 6px solid #fff;`
            : "border-top: 6px solid #254478;"};
        transition: transform ease-in-out 0.3s;
    }

    .dropdown-mobile {
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        top: 4.5rem;
        right: 0rem;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.31);
        z-index: 1;
        border-radius: 15px;
    }

    .dropdown-mobile span:hover {
        font-weight: 700;
        cursor: pointer;
    }

    .dropdown-mobile span {
        float: none;
        color: rgba(0, 0, 0, 1);
        font-weight: 300;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: center;
    }

    .dropdown-mobile-dark {
        background-color: rgb(38, 56, 84); !important;
    }

    .dropdown-mobile-dark span {
        color: white;
    }

    svg{
        cursor: pointer;
    }

    .showNotification{
        position: relative;
    }

    .notificationDropdown {
        position: absolute;
        top: 2.8rem;
        min-width: 300px;
        right: 0rem;
        background-color: ${(props) =>
          props.theme === "light" ? `#f3f3f3` : "#202F47"};
        box-shadow: 0px 0px 10px 0px #00000040;
        z-index: 1;
        border-radius: 15px;
    }
`;

export const UserProfile = ({ theme, navbar, desktopToggle }) => {
  const { profile } = useContext(GlobalUserProfileContext);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [socket, setSocket] = useState(null);
  const audio = useRef(new Audio(notificationAudio));
  const [notificationList, setNotificationList] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [decoration, setDecoration] = useState("");
  const [viewDetailScreenData, setViewDetailScreenData] = useState(null);
  const userInteracted = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    profile && dispatch(projectsList(profile.id));
  }, [profile]);

  const handleOpenModel = useCallback(async (id, decorator) => {
    let data = await fetchSingleNotificationID(id);
    if (data) {
      ReallyOpenModel(data, decorator);
    }
  }, []);

  const ReallyOpenModel = useCallback((data, decorator) => {
    setViewDetailScreenData(data);
    setDecoration(decorator);
  }, []);

  const [canRender] = useLoadDependenciesBeforeRender({
    actionsProgressWithKeys: {
      a: notificationList?.isFetching,
    },
  });

  const callBackPopupNotification = useCallback((item) => {
    userInteracted.current && audio.current.play();
    const content = (
      <NotificationListItem handleOpenModal={handleOpenModel} item={item} />
    );
    notifyToast({
      content: content,
      type: "info",
      noAutoClose: true,
    });
  }, []);

  useEffect(() => {
    if (socket) {
      socket.connInstance.onmessage = function (e) {
        const socketData = JSON.parse(e.data);
        const { type, data } = socketData;

        switch (type) {
          case "event_received":
            const { id, type } = data;

            if (type !== "heatmap_vaf") {
              fetchSingleNotificationID(id).then((data_) => {
                if (data_) {
                  callBackPopupNotification(data_);
                  APIUtils.dataStateMutator(
                    { ...notificationList },
                    {
                      data: [data_, ...(notificationList?.data || [])],
                    },
                    setNotificationList
                  );
                }
              });
            }

            break;
          default:
            break;
        }
      };
    }
  }, [socket, notificationList]);

  const fetchSingleNotificationID = useCallback(async (id) => {
    const endpoint =
      APIEndpoints.instance.stream.analytic.global_notification.notification.main.get(
        id
      );
    return await (
      await axiosInstance()
    )
      .get(endpoint)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return null;
      });
  }, []);

  useEffect(() => {
    APIUtils.fetchItemData({
      endpoint:
        APIEndpoints.instance.stream.analytic.global_notification.notification.main.getAll(
          {
            ordering: "-event_time",
          }
        ),
      item: notificationList,
      setItem: setNotificationList,
      fetchAllListItem: false,
    });
  }, []);

  useEffect(() => {
    if (viewDetailScreenData) {
      setEditModal(true);
    }
  }, [viewDetailScreenData]);

  useEffect(() => {
    if (!socket) {
      const wsConnManagerInstance = new WSUtilsHelperConnectionManager(
        "/analytic/event",
        setSocket
      );
      wsConnManagerInstance.initiate();
      return () => {
        wsConnManagerInstance.close();
      };
    }
  }, []);

  const onScroll = async (e) => {
    if (
      e.target.scrollTop + e.target.getBoundingClientRect().height >=
      e.target.scrollHeight - 10
    ) {
      if (!notificationList?.isFetching) {
        APIUtils.fetchItemData({
          endpoint:
            APIEndpoints.instance.stream.analytic.global_notification.notification.main.getAll(
              {
                ordering: "-event_time",
                offset: (notificationList?.data || []).length,
              }
            ),
          item: notificationList,
          setItem: setNotificationList,
          fetchAsAppendMode: true,
          fetchAllListItem: false,
        });
      }
    }
  };

  useEffect(() => {
    const handleUserInteraction = () => {
      userInteracted.current = true;
      // Remove event listeners after first interaction
      window.removeEventListener("click", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
    };

    // Attach event listeners for user interaction
    window.addEventListener("click", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction);

    return () => {
      // Clean up event listeners
      window.removeEventListener("click", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
    };
  }, []);

  return (
    <StyledComponent theme={theme}>
      {profile && (
        <div className='userProfile'>
          {!navbar && (
            <ProfileContainer desktopToggle={desktopToggle}>
              <div className='gradient-bg'>
                <ProfilePicture
                  src={profile?.picture?.file || ""}
                  alt={profile?.picture?.label}
                  loading='lazy'
                />
              </div>

              {!desktopToggle && (
                <ProfileInfo>
                  {/* Right Side Content */}
                  <ProfileHeader
                    onClick={() => {
                      const isMobile =
                        window.matchMedia("(max-width: 768px)").matches;
                      if (isMobile) {
                        setShowDropdown(!showDropdown);
                      } else {
                        setIsProfileOpen(!isProfileOpen);
                      }
                    }}
                  >
                    <span>{profile?.username}</span>
                    <svg
                      width='12'
                      height='10'
                      viewBox='0 0 14 10'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M1 2L6.5 7L12 2'
                        stroke={
                          theme === "dark" ? "rgb(94, 132, 195)" : "#a70101"
                        }
                        strokeWidth='3'
                      />
                    </svg>
                  </ProfileHeader>

                  <FooterLogout>
                    <Link
                      to='/logout'
                      className='orange-text text-small side-nav-logout'
                    >
                      {LogoutIcon()} {"  "}
                      <small className='black-text'>Log Out</small>
                    </Link>
                  </FooterLogout>
                </ProfileInfo>
              )}

              {isProfileOpen && !desktopToggle && (
                <ProfileView
                  closeProfile={() => setIsProfileOpen(!isProfileOpen)}
                />
              )}
            </ProfileContainer>
          )}
          {navbar && (
            <div className='header-hide'>
              <div className='showNotification'>
                <span
                  onClick={() => setNotificationOpen(!notificationOpen)}
                  style={{ cursor: "pointer" }}
                >
                  <BellIcon theme={theme} />
                </span>
                {notificationOpen && (
                  <div className='notificationDropdown'>
                    <LiveStreamNotificationContainer
                      onScroll={onScroll}
                      canRender={canRender}
                      notificationList={notificationList}
                      handleOpenModal={handleOpenModel}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      <ModalComponent
        modalLabel={"Show Camera Detail"}
        isOpen={editModal}
        callbackCloseModal={() => {
          setEditModal(false);
        }}
        customStyling2={true}
      >
        <ViewDetailScreen
          callbackCancel={() => {
            setEditModal(false);
          }}
          featureData_={viewDetailScreenData}
          decoration={decoration}
          fetchSingleNotificationByID={fetchSingleNotificationID}
        />
      </ModalComponent>
    </StyledComponent>
  );
};
